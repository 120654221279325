/*
 * @Author: CL
 * @Date: 2021-07-21 22:50:47
 * @LastEditTime: 2021-07-22 10:47:04
 * @Description: 账号相关的api
 */

import request from './request';

/**
 * 分页查询账号信息
 */
export function queryAdmins(page = 1, size = 10, data = {}) {
  return request({
    url: `/api/admin/${page}/${size}`,
    method: 'get',
    data
  })
}

/**
 * 删除账号
 */
export function delAdmin(id) {
  return request({
    url: `/api/admin/${id}`,
    method: 'delete'
  })
}

/**
 * 添加账号
 */
export function addAdmin(data) {
  return request({
    url: '/api/admin/add',
    method: 'post',
    data
  })
}

/**
 * 修改账号 
 */
export function editAdmin(id, data) {
  return request({
    url: `/api/admin/${id}`,
    method: 'put',
    data
  })
}
