<!--
 * @Author: CL
 * @Date: 2021-07-22 10:23:16
 * @LastEditTime: 2021-07-22 10:44:50
 * @Description: 修改账号的模态框
-->

<template>
  <div class="editadminmodel-contain">
    <div class="main">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="110px"
        class="demo-ruleForm"
        label-position="left"
      >
        <el-form-item label="账号" prop="loginId">
          <el-input clearable class="cl-dialog" v-model="ruleForm.loginId" placeholder="必填，请输入账号"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="loginPwd">
          <el-input clearable class="cl-dialog" v-model="ruleForm.loginPwd" placeholder="必填，请输入密码"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div class="footer cl-dialog-footer">
      <el-button class="my-button" @click="closeModel">取消</el-button>
      <el-button class="my-button" type="primary" @click="save">保存</el-button>
    </div>
  </div>
</template>

<script>
import { editAdmin } from '@/api/admins';

export default {
  data(){
    return {
      ruleForm: {
        loginId: '',
        loginPwd: ''
      },

      rules: {
        loginId: [
          { required: true, message: '账号不能为空', trigger: 'blur' },
          { min: 3, max: 12, message: '账号长度在 3 到 12 个字符', trigger: 'blur' }
        ],
        loginPwd: [
          { required: true, message: '密码不能为空', trigger: 'blur' },
          { min: 6, max: 12, message: '密码长度需在 3 到 12 个字符', trigger: 'blur' }
        ],
      }
    }
  },

  methods: {
    save(){
      this.$refs['ruleForm'].validate(async (valid) => {
        if (!valid) {
          return;
        }
        try{
          const res = await editAdmin(this.ruleForm.id, this.ruleForm);
          if(res.code == 200){
            this.$showMessage({
              type: 'success',
              message: res.msg
            })
            this.close('refresh');
          }
        }catch(err){
          console.log(err);
        }
      });
    },

    closeModel(){
      this.close();
    },

    close(data){
      this.$emit('closeEditAdminModel', false, data);
      this.$refs['ruleForm'].resetFields();
    },

    firstTo(data){
      this.ruleForm = Object.assign({}, data);
    }
  },

  props: {
    editInfo: {
      type: Object,
      required: true
    }
  },

  watch: {
    editInfo: {
      handler(val){
        this.firstTo(val);
      },
      immediate: true
    }
  }
}
</script>

<style>

</style>
