<!--
 * @Author: CL
 * @Date: 2021-07-21 22:26:56
 * @LastEditTime: 2021-07-22 12:54:39
 * @Description: 账号管理
-->

<template>
  <div class="adminback-contain cl-wrap">
    <div class="cl-table-main" v-loading="loading">
      <div class="main-top">
        <div>账号信息</div>
        <el-button type="primary" size="medium" icon="el-icon-plus" @click="openAddAdminModel">添加</el-button>
      </div>
      <el-table
        :data="tableData"
        style="width: 100%"
        :header-cell-style="{ textAlign: 'center' }"
        class="table-wrap"
        max-height="580"
      >
        <el-table-column
          prop="loginId"
          label="账号"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="loginPwd"
          label="密码"
          align="center"
        >
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              size="mini"
              icon="el-icon-edit"
              @click="handleEdit(scope.row)">修改</el-button>
            <el-button
              size="mini"
              type="danger"
              icon="el-icon-delete"
              @click="handleDelete(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div>
        <Pagination 
          :page="page" 
          :size="size" 
          :total="total" 
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
        />
      </div>
    </div>

    <Dialog 
      :isShow="addAdminModel" 
      title="添加账号"
      :width="40" 
    >
      <addAdmin @closeAddAdminModel="closeAddAdminModel" />
    </Dialog>

    <Dialog 
      :isShow="editAdminModel" 
      title="修改账号"
      :width="40" 
    >
      <editAdmin 
        @closeEditAdminModel="closeEditAdminModel" 
        :editInfo="editInfo"
      />
    </Dialog>
  </div>
</template>

<script>
import Dialog from '@/components/Dialog';
import Pagination from '@/components/Pagination';
import { queryAdmins, delAdmin } from '@/api/admins';
import addAdmin from './components/addAdminModel.vue';
import editAdmin from './components/editAdminModel.vue';

export default {
  data(){
    return {
      page: 1,
      size: 10,
      total: 0,
      tableData: [],
      loading: true,

      addAdminModel: false,
      editAdminModel: false,
      editInfo: {}
    }
  },

  methods: {
    /**
     * 添加账号
     */
    openAddAdminModel(){
      this.addAdminModel = true;
    },

    /**
     * 关闭添加账号的模态框
     */
    closeAddAdminModel(flag, isRefresh){
      this.addAdminModel = flag;
      if(isRefresh === 'refresh'){
        this.queryAdmin();
      }
    },

    /**
     * 关闭修改账号的模态框
     */
    closeEditAdminModel(flag, isRefresh){
      this.editAdminModel = flag;
      if(isRefresh === 'refresh'){
        this.queryAdmin();
      }
    },

    /**
     * 修改密码，打开修改账号的模态框
     */
    handleEdit(data){
      this.editAdminModel = true;
      this.editInfo = data;
    },

    /**
     * 删除密码
     */
    handleDelete(data){
      const { id } = data;
      this.$utils.confirm('您确定要删除该账号吗?').then(async () => {
        const res = await delAdmin(id);
        if(res.code == 200){
          this.$showMessage({
            type: 'success',
            message: '删除成功!'
          });
          this.queryAdmin();
        }
      }).catch(err => {
        //点击了取消
        this.$showMessage({
          type: 'info',
          message: '已取消删除'
        }); 
      })
    },

    /**
     * size发生改变触发
     */
    handleSizeChange(val){
      this.size = val;
      this.queryAdmin();
    },

    /**
     * page发生变化
     */
    handleCurrentChange(val){
      this.page = val;
      this.queryAdmin();
    },
    
    /**
     * 分页查询账号信息
     */
    async queryAdmin(){
      this.loading = true;
      const { page, size } = this;
      try{
        const res = await queryAdmins(page, size);
        this.loading = false;
        if(res.code == 200){
          const { rows, count } = res.data;
          this.total = count;
          this.tableData = rows;
        }
      }catch(err){
        console.log(err);
      }
    }
  },

  created(){
    this.queryAdmin();
  },

  components: {
    Pagination,
    Dialog,
    addAdmin,
    editAdmin
  }
}
</script>

<style lang="less" scoped>

</style>