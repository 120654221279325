import { render, staticRenderFns } from "./addAdminModel.vue?vue&type=template&id=13f7ce7b&scoped=true&"
import script from "./addAdminModel.vue?vue&type=script&lang=js&"
export * from "./addAdminModel.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13f7ce7b",
  null
  
)

export default component.exports